import React from "react"
import { Link, graphql } from "gatsby"

import Pagination from "../components-tr/pagination.tr"
import Layout from "../components/layout"
import Seo from "../components-tr/seo"
import Article from "../components-tr/article.tr"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, currentPagePath, totalPages, pageNumber },
}) => {
  const posts = data.allWpPost.nodes
  const category = data.wpCategory
  const categories = data.allWpCategory.nodes

  const filterCategories = categories.filter(category => category.posts.nodes.length)

  const metaTitle = pageNumber === 1 ? `${category.name} | Son Gelişmeler Ve Hikayeler` : `${category.name} | Son Gelişmeler Ve Hikayeler ${pageNumber}. sayfa.`
  const metaDescription = pageNumber === 1 ? `Tio blogta ${category.name?.toLowerCase()} hakkında son gelişmeleri ve hikayeleri okuyun.` : `Tio blogta ${category.name.toLowerCase()} hakkında son gelişmeleri ve hikayeleri okuyun. ${pageNumber}. sayfa.`

  if (!posts.length) {
    return (
      <Layout navbarTheme="dark" className="bg-cream">
        <Seo title={metaTitle} description={metaDescription} />
        <p>İçerik bulunamadı.</p>
      </Layout>
    )
  }

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title={metaTitle} description={metaDescription} />
      <div className="mx-5 md:mx-16 pt-32 pb-5">
        <div className="flex flex-wrap gap-x-12 gap-y-6">
          {filterCategories.map(category => <Link lang="tr" to={`/tr/blog/category/${category.slug}`} key={category.id} className="text-blue uppercase font-bold text-sm" activeClassName="underline">{category.name}</Link>)}
        </div>

        <h1 className="text-blue font-bold text-6xl pt-[3.125rem]">{category.name}</h1>
      </div>
      <div className="mx-2.5 md:mx-[3.375rem]">
        <ol className="lg:flex flex-wrap list-none">
          {posts.map((post, index) => {
            const title = post.title
            const featuredImage = {
              data: post.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
              alt: post.featuredImage?.node?.alt || ``,
            }
            return (
              <Article key={index} post={post} title={title} featuredImage={featuredImage} />
            )
          })}
        </ol>
      </div>

      <Pagination
        rootSlug={`/tr/blog/category/${category.slug}`}
        totalPages={totalPages}
        previousPagePath={previousPagePath}
        nextPagePath={nextPagePath}
        currentPagePath={currentPagePath}
      />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressCategoryPostArchiveTr(
    $offset: Int! 
    $postsPerPage: Int!
    $language: String!
    $categoryId: String!
   ) {
    allWpPost(
      filter: {language: {slug: {eq: $language}}, categories: {nodes: {elemMatch: {id: {eq: $categoryId}}}}}
      sort: {fields: [date], order: DESC}
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY", locale: $language)
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    wpCategory(id: {eq: $categoryId}) {
        id
        slug
        name
    }
    allWpCategory(filter: {language: {slug: {eq: $language}}, posts: {nodes: {elemMatch: {language: {slug: {eq: $language}}}}}}) {
      nodes {
        slug
        id
        name
        posts {
          nodes {
            id
            title
          }
        }
      }
    }
  }
`
